import React from "react"
import Page from "react-page-loading"

import { Link } from "gatsby"
import GlobalStyle from "../components/globalStyle"
import styled from "styled-components"
import SEO from "../components/seo"
import Hero from "../components/hero"
import CookieConsent from "react-cookie-consent"
// import P5Mouse from "../components/p5Mouse/p5"

//////////

const PageContainer = styled.div`
  position: relative;
  margin-bottom: 240px;
  width: 100%;
`

const Container = styled.div`
  max-width: 900px;
  margin: 0px auto;
  padding: 0 16px;
  font-size: 60px;
  position: relative;
  top: 160px;
  @media screen and (max-width: 600px) {
    font-size: 40px;
  }
  .space {
    margin-bottom: 16px;
    @media screen and (max-width: 600px) {
      margin-bottom: 16px;
    }
  }

  .linkStyle {
    color: rgba(255, 255, 255, 0);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.5);
    transition: 0.3s ease-in-out;

    &:hover {
      color: rgba(255, 255, 255, 1);
      transition: 0.4s ease-in-out;
      -webkit-text-stroke-color: rgba(255, 255, 255, 0);
    }
  }
`

// const P5MouseContainer = styled.div`
//   width: 100%;
//   height: 100vh;
//   position: absolute;
//   top: 0;
//   left: 0;
// `

const IndexPage = () => (
  <div>
    <Page loader={"bar"} color={"#282828"} size={10} duration={1}>
      <SEO title="Accueil" />
      <GlobalStyle />
      {/* <P5MouseContainer>
        <P5Mouse />
      </P5MouseContainer> */}
      <PageContainer>
        <CookieConsent
          location="bottom"
          buttonText="J'accepte!"
          cookieName="myAwesomeCookieName2"
          style={{
            background: "rgba(255,255,255,0.7)", //#2B373B
            color: "#282828",
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif;",
            letterSpacing: "0.5px",
            fontWeight: "400",
          }}
          buttonStyle={{
            color: "#fff", //#4e503b
            background: "#282828",
            textTransform: "uppercase",
            fontSize: "13px",
            padding: "8px 12px",
            fontWeight: "700",
            fontFamily: "'Open Sans', sans-serif;",
            letterSpacing: "0.6px",
          }}
          expires={150}
        >
          Ce site utilise des témoins de navigation.
          {/* <span style={{ fontSize: "10px" }}>
            This bit of text is smaller :O
          </span> */}
        </CookieConsent>

        <Hero />
        <Container className="style">
          {/* <div className="space">
            <Link to="/p5_12" class="linkStyle">
              PLaceHolder
            </Link>
          </div> */}
          <div className="space">
            <Link to="/p5_11" class="linkStyle">
              Screen class pixels
            </Link>
          </div>
          <div className="space">
            <Link to="/p5_10" class="linkStyle">
              The square tunnel
            </Link>
          </div>
          <div className="space">
            <Link to="/p5_9" class="linkStyle">
              Responsive arrow grid
            </Link>
          </div>
          <div className="space">
            <Link to="/p5_8" class="linkStyle">
              The circles of chaos
            </Link>
          </div>
          <div className="space">
            <Link to="/p5_7" class="linkStyle">
              WebGL - Outlined boxes
            </Link>
          </div>
          <div className="space">
            <Link to="/p5_6" class="linkStyle">
              WebGl - Boxes around box
            </Link>
          </div>
          <div className="space">
            <Link to="/p5_5" class="linkStyle">
              Drop a Bezier
            </Link>
          </div>
          <div className="space">
            <Link to="/p5_4" class="linkStyle">
              Minimal line generation
            </Link>
          </div>
          <div className="space">
            <Link to="/p5_3" class="linkStyle">
              Line generation
            </Link>
          </div>
          <div className="space">
            <Link to="/p5_2" class="linkStyle">
              Infinite dancing squares
            </Link>
          </div>
          <div className="space">
            <Link to="/p5_1" class="linkStyle">
              Infinite random circles
            </Link>
          </div>
        </Container>
      </PageContainer>
    </Page>
  </div>
)

export default IndexPage

//htacess
//--
// RewriteEngine On
// RewriteCond %{HTTPS} off
// RewriteRule ^(.*)$ https://%{HTTP_HOST}%{REQUEST_URI} [L,R=301]
